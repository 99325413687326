const TIMEOUT=86400;
let cache={
    set:function (name, value, timeout){
        //判断name的值是否为空
        if(name==null || name.length==0 || name==undefined || name=='') return false;
        //判断value的值是否为空
        if(value==null || value.length==0 || value==undefined || value=='') return false;
        if(timeout==null || timeout.length==0 || timeout==undefined || timeout=='') timeout=TIMEOUT;
        let exp = new Date();
        localStorage.setItem(name,JSON.stringify({
            value:value,
            expires:exp.getTime() + timeout * 1000
        }));
    },
    get:function (name){
        try {
            let o = JSON.parse(localStorage.getItem(name))
            if (!o || o.expires < Date.now()) {
                return null;
            } else {
                return o.value;
            }
        } catch (e) {
            return localStorage.getItem(name);
        } finally {

        }
    },
    delete:function (name){
        localStorage.removeItem(name)
    },
    delAll:function () {
        localStorage.clear();
    },
    check_cache_exist(obj){ //存在 true 过期或者不存在 false
        return !(obj == null || obj == undefined || obj.length == 0);
    },
    checkCacheExist(obj){ //存在 true 过期或者不存在 false
        return !(obj == null || obj == undefined || obj.length == 0);
    }
}

module.exports = cache