module.exports={
    base_url:'',
    dev_url:'',
    // test_url:'http://localhost:7083',
    // test_url:'http://chatgptapi-dev.genkigo.net',
    test_url:'https://chatgptapi.genkigo.net',
    release_url:'https://chatgptapi.genkigo.net',
    upload_url:'https://centerapi.genkigo.net/common/uploadFile',
    // test_url:'http://consoleapi-dev.genkigo.net',
    upload:{
        pic_ext:'png,jpeg,jpg,gif',
        // pic_size:'4194304',
        pic_size:'10485760',
        video_ext:'mp4,rmvb,avi,mkv',
        video_size:'20971520',
        threeD:'obj,mtl,png,jpeg,jpg',
    },
    //额外路由 不校验用户登陆
    exclude_path:[
        '/user/info'
        ,'/login'
        ,'/register'
        ,'/socket'
        ,'/socket/chat'
        ,'/cms'
        ,'/apply'
        ,'/info'
        ,'/'
        ,'/presale'
    ],
}