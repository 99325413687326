import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/css/reset.css'
import '@/assets/css/base.css'


import 'vant/lib/index.css';
import Vant, {Toast} from 'vant';

Vue.use(Vant);
Vue.config.productionTip = false
import config from '@/utils/config'
import {get, post} from "./utils/http";
import cache from "./utils/cache";
import {goBack, jumpUrl, formatDate, toThousands} from "./utils/utils"

const utils = require("./utils/utils");
let user = require("./utils/user");

Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$cache = cache;
Vue.prototype.$goBack = goBack;
Vue.prototype.$utils = utils;

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
    let path=to.path;
    let exclude_path=config.exclude_path;
    // if(exclude_path.indexOf(path)==-1){
    // // if (to.path != '/login' ) { //检查是否登陆
    //     user.UserInit().then(info => {
    //         //已经登陆
    //     }).catch(err => {
    //         let reg = to.query.reg;
    //         if(reg==null || reg==undefined) reg='';
    //         Toast({
    //             message: '请先登陆',
    //             icon: 'warning-o',
    //             onClose() {
    //                 // next({path: '/login?reg=' + reg})
    //                 next({path: '/login'})
    //             }
    //         });
    //         console.log(err)
    //     });
    // }
    /* 路由发生变化修改页面title */
    // @ts-ignore
    if (to.meta.title) {
        // @ts-ignore
        document.title = to.meta.title
    }
    if (to.path == '/') {
        // @ts-ignore
        // let param = new URLSearchParams(to.query).toString(); //携带参数
        let param='';
        // next('/share_data?' + param);
        // next('/chat?' + param);
       // next('/' + param);
        next();
    }
    next();
    window.scrollTo(0, 0)
})


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
