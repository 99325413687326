<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import {Toast} from "vant";
let _this;
import {checkIsWxBrowser, initCode} from "@/utils/libWechat";
import cache from '@/utils/cache'
import config from '@/utils/config'

export default {
  name: "App",
  data() {
    return {
      is_wx: false,
    }
  },
  created() {
    _this = this;
    //_this.$shareCode.init();
    // console.log(_this.is_wx);
    if (checkIsWxBrowser()) {
      // _this.$store.sett
      let openid = cache.get("UserWxOpenID");
      // Toast(openid);
      if (openid == false || openid == '' || openid == null) {
        initCode().then(res => {
          // Toast(res.openid);
          cache.set("UserWxOpenID", res.openid)
        }).catch(err => {
          Toast({
            message: err,
          });
          console.log(err);
        })
      }
    }
  },
  methods:{

  }
}

</script>


<style lang="scss">
html {
  background: #F4F6FD;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

</style>
