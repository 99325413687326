import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    // {
    //     path: '/',
    //     name: 'home',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/index.vue'),
    //     children: [
    //         {
    //             path: '/withdraw',
    //             name: 'withdraw',
    //             component: () => import(/* webpackChunkName: "about" */ '../views/tabbar/withdraw.vue'),
    //             meta: {
    //                 title: '',
    //                 isFresh: true,
    //             }
    //         }, {
    //             path: '/user',
    //             name: 'user',
    //             component: () => import(/* webpackChunkName: "about" */ '../views/tabbar/user.vue'),
    //             meta: {
    //                 title: '',
    //                 isFresh: true,
    //             }
    //         }, {
    //             path: '/share_data',
    //             name: 'share_data',
    //             component: () => import(/* webpackChunkName: "about" */ '../views/tabbar/shareData.vue'),
    //             meta: {
    //                 title: '',
    //                 isFresh: true,
    //             }
    //         },
    //     ],
    // }, {
    //     path: '/login',
    //     name: 'login',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/common/login.vue'),
    //     meta: {
    //         title: '',
    //         isFresh: true,
    //     }
    // },{
    //     path: '/user/password/reset',
    //     name: 'user/password/reset',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/user/resetPassword.vue'),
    //     meta: {
    //         title: '',
    //         isFresh: true,
    //     }
    // },{
    //     path: '/user/bankcard',
    //     name: 'user/bankcard',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/user/bankcard.vue'),
    //     meta: {
    //         title: '',
    //         isFresh: true,
    //     }
    // },{
    //     path: '/chat',
    //     name: 'chat',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/chat/chat.vue'),
    //     meta: {
    //         title: '',
    //         isFresh: true,
    //     }
    // },
    // {
    //     path: '/register',
    //     name: 'register',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/common/register.vue'),
    //     meta: {
    //         title: '',
    //         isFresh: true,
    //     }
    // },{
    //     path: '/user/info',
    //     name: 'user/info',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/user/info.vue'),
    //     meta: {
    //         title: '',
    //         isFresh: true,
    //     }
    // }
    // , {
    //     path: '/socket',
    //     name: 'socket',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/socket/index.vue'),
    //     meta: {
    //         title: '',
    //         isFresh: true,
    //     },
    //     children:[
    //
    //     ]
    // }, {
    //     path: '/socket/chat',
    //     name: 'socket/chat',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/socket/chat.vue'),
    //     meta: {
    //         title: '',
    //         isFresh: true,
    //     },
    // },
    {
        path: '/',
        name: 'index',
        component: () => import(/* webpackChunkName: "about" */ '../views/cms/apply/index.vue'),
        meta: {
            title: '',
            isFresh: true,
        },
    },{
        path: '/apply',
        name: 'cms/apply',
        component: () => import(/* webpackChunkName: "about" */ '../views/cms/apply/apply.vue'),
        meta: {
            title: '',
            isFresh: true,
        },
    },{
        path: '/info',
        name: 'cms/info',
        component: () => import(/* webpackChunkName: "about" */ '../views/cms/apply/info.vue'),
        meta: {
            title: '',
            isFresh: true,
        },
    },{
        path: '/presale',
        name: 'presale',
        component: () => import(/* webpackChunkName: "about" */ '../views/sale/presale.vue'),
        meta: {
            title: '爱喳猫智能盒芯预购',
            isFresh: true,
        },
    },{
        path: '/success',
        name: 'success',
        component: () => import(/* webpackChunkName: "about" */ '../views/sale/success.vue'),
        meta: {
            title: '爱喳猫智能盒芯预购',
            isFresh: true,
        },
    },{
        path: '/address',
        name: 'address',
        component: () => import(/* webpackChunkName: "about" */ '../views/sale/address.vue'),
        meta: {
            title: '收货地址',
            isFresh: true,
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
